<template>
<div>
	<CRow>
		<CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <h4>Debit User</h4>     
          </CCardHeader>
          <CCardBody> 
           <CForm>  
              <!--b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Phone Number</label>
                  </b-col>   
                  <b-col sm="9"> 
                      <vue-typeahead-bootstrap
                        v-model="phoneNum"              
                        :data="optNumber">
                        <template #append>
                          <CButton color="success" v-on:click="actionChange()">Select</CButton>
                        </template>
                      </vue-typeahead-bootstrap>
                   </b-col> 
                  </b-col>
              </b-row><br/-->
             <CInput
                 type="number"
                 id="phoneNumberSearch"
                 class="phoneNumber"
                 description="Please add country code within phone number (62, 65, etc)"
                 autocomplete="off"
                 label="Phone Number"
                 v-model="phoneNum"
                 horizontal
             >
               <template #append>
                 <CButton color="success" :disabled="!isDisabledSlc" v-on:click="actionChange()">Select</CButton>
               </template>
             </CInput>
             <CRow>
               <CCol col="12" class="text-left">
                 <CAlert v-bind:color="coloruser" v-if="seenuser" id="alertuser">{{ msguser }}</CAlert>
               </CCol>
             </CRow>
             <CInput
                type="text" 
                label="User Indentity"
                v-model="userId"
                :readonly="true"
                horizontal                
              />
              <CInput
                type="text" 
                label="Display Name"
                v-model="displayName"
                :readonly="true"
                horizontal                
              /> 
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Wallet Name</label>
                  </b-col>   
                  <b-col sm="9"> 
                      <b-form-select v-model="walletId" :options="optWallet"></b-form-select>
                  </b-col>
              </b-row><br/>
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Transaction Type</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-select v-model="trType"  :options="optTrType"></b-form-select>
                   </b-col> 
              </b-row><br/>
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Currency</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-select v-model="currency" :options="optCurrency"></b-form-select>
                  </b-col>
              </b-row><br/>
              <CInput
                type="number"               
                label="Amount"
                v-model="amount"  
                min="1" 
                placeholder="Enter Debit Amount"
                horizontal 
              />
              <CTextarea
                label="Customer Message"
                v-model="custMsg"  
                placeholder="Type Customer Message..."
                description="Maximum 130 characters"
                maxlength="130" 
                horizontal
                rows="3"               
              />
              <!-- <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Customer Message</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-select v-model="custMsg" :options="optCustMsg"></b-form-select>
                   </b-col> 
                  </b-col>
              </b-row><br/> -->
              
              <CTextarea
                label="Reason"
                v-model="reason"  
                placeholder="Type Reason..."
                description="Max. 180 characters. For User-requested top-ups, you must include user phone number, date/time requested, purpose and other comments.  Example: 6580134177, 23/3/2024 1PM, To remit"
                horizontal
                rows="3"               
              />
               <CInput
                   label="Comment"
                   v-model="comments"
                   placeholder="Type Comment..."
                   description="Enter the Bank Reference here that’s captured in the bank record. Eg: EWTOPUP812DFJ123"
                   maxlength="50"
                   horizontal
               />
              <span v-if="seenOTP" >

                <CInput
                  type="text" 
                  label="Tr Reference"
                  v-model="trRef"
                  :readonly="true"
                  horizontal                
                />        

                <CInput
                  type="text" 
                  label="OTP"
                  v-model="otp"
                  placeholder="Type OTP"             
                  horizontal                
                />   
              
              </span>
              <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
              </CRow>
              <div class="text-center">
                  <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
              </div>              
              <b-row>
                  <b-col sm="3"></b-col>   
                  <b-col sm="9"> 
                     <b-button-group>                     
                      <CButton v-on:click="actionSubmit()" color="primary">Submit</CButton>
                      <CButton v-if="sent" v-on:click="actionResend()" color="success">Resend OTP</CButton>
                    </b-button-group>
                  </b-col>
              </b-row><br/>
            </CForm>  		          
           </CCardBody>
        </CCard>
      </CCol>
     </CRow>
</div>
</template>

<script>
 import AuthService from '@/api/AuthService.js';
 import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
 import TransactService from "@/api/TransactService";
 
  export default { 
    name: 'debit',
    components: {
      VueTypeaheadBootstrap,      
    },     
    data() {
      return {  
          trRef:'',
          otp:'',
          seenOTP:false, 
          sent:false,
          spinner:false,
          optCustMsg:[
            {"value":1, "text" : "Lorem Ipsum is simply dummy text of the printing and typesetting industry"},
            {"value":2, "text" : "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"},
            {"value":3, "text" : "when an unknown printer took a galley of type and scrambled it to make a type specimen book"},
          ],
          optCurrency: [],
          optNumber:[],
          optWallet:[],
          phoneNum:'',
          baseItems:[],
          baseItems2:[],
          baseUserId:[],
          basePhoneNum:[],
          optTrType:[],
          trType:'',
          userId:'',
          displayName:'',
          walletId:'',
          currency:'',
          amount:'',
          custMsg:'',
          comments:'',
          reason:'',
          isDisabledSlc: true,
          seen:false,
          seenuser:false,
          msg :'',
          color:''
      };      
    },
    created () {        
        this.getConfigList();  
    },
    methods: { 
      
      actionResend(){
        this.spinner = true;
        this.debitCreditValidate();
      },

      actionSubmit(){
        this.spinner = true;
        let params = {}; var validate = [];
        params["amount"]   = this.amount;
        params["currency"] = this.currency;         
        params["reason"]   =  this.reason;        
        params["customerMessage"] = this.custMsg;
        params["transactionType"] = this.trType;
        let add = {'userId' : this.userId };
        params["userIdentify"] = add;                  
        params["userType"] = "USER";              
        params["walletId"] =  this.walletId;   

        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }  
        
        params["otp"] = this.otp;       
        params["requestRef"] = this.trRef;
        params["walletGroup"] = 0; console.log(params);

        if(!validate.includes(false)){ 
            if(!this.otp){               
               if(this.sent == false){
                  this.debitCreditValidate(); 
               } else { 
                 this.msg   = 'OTP is required!'; 
                 this.color = 'warning'; this.seen  = true; this.spinner = false;
                 setTimeout( () =>  this.seen=false , 5000);
               }                             
            } else {   
               this.debitUser(params);
            }            
        } else {           
           this.msg   = 'Data Not Complete, All Data Required!'; 
           this.color = 'warning'; this.seen  = true; this.spinner = false;
           setTimeout( () =>  this.seen=false , 5000);
        }       

      },

      actionChange(){
        if(this.phoneNum){
          this.currency='';
          this.amount='';
          this.reason='';
          var detail = this.getUserDetail(this.phoneNum);
        } else {
           this.msg   = 'Please select phone number first !'; 
           this.color = 'warning'; this.seen  = true;
           setTimeout( () =>  this.seen=false , 5000);
        }
      },

      debitComplete() {
        this.msg      = 'Debit user successfully done';
        this.color    = 'success';
        this.phoneNum ='';
        this.userId   ='';
        this.displayName ='';
        this.trType      = '';
        this.walletId    ='';
        this.currency    ='';
        this.amount      ='';
        this.custMsg     ='';
        this.reason      ='';
        this.trRef       ='';
        this.otp         ='';
        this.seenOTP     = false;
        this.sent        = false;
      },

      debitCreditValidate: function() {
        let params = {};
        params["amount"]   = this.amount;
        params["currency"] = this.currency;
        params["reason"]   =  this.reason;
        params["direction"] = "DEBIT_USER";
        params["customerMessage"] = this.custMsg;
        params["comment"] = this.comments;
        params["transactionType"] = this.trType;
        params["userId"] = this.userId;
        params["userType"] = "USER";
        params["walletId"] =  this.walletId;
        TransactService.systemTransactionCreate(params).then(async resp => {
          if (resp.statusCode === 'S0000') {
            this.trRef = resp.message;
            this.seenOTP = true;
            this.sent = true;
            this.msg = "A request has been sent to your approver";
            this.color = 'success';
            this.debitComplete();
            this.spinner = false;
          } else {
            this.msg = resp.message;
            this.color = 'warning';
          }
          this.seen = true;
          this.spinner = false;
          setTimeout(() => this.seen = false, 5000);
        }, error => {
          console.log(error);
        });
      },

      debitCreditValidateBackup: function() {
          AuthService.debitCreditValidate(true).then(resp => {
            if(resp.statusCode=='S0000'){
               this.trRef   = resp.message;
               this.seenOTP = true; this.sent = true;
               this.msg     = "An OTP Code has been sent to your Approver. Please request code from Approver!"; 
               this.color   = 'info'; 
            } else {
               this.msg   = resp.message; 
               this.color = 'warning'; 
            } 
            this.seen = true; this.spinner =false;
            setTimeout( () =>  this.seen=false , 5000);

           }, error => {
              console.log('error');    
          });
      },     
     
      debitUser: function(params) {
        AuthService.debitUser(params).then(resp => {  console.log(resp);
              if(resp.message==='Success'){
                this.debitComplete()
              } else {
                this.msg   = resp.data.message; 
                this.color = 'warning'; 
              }
              this.seen  = true; this.spinner =false;
              setTimeout( () => this.seen=false, 5000);          
        }, error => {
            console.log('error');         
        });
      },

      getUserDetail: function(phoneNumber) {
        if(phoneNumber !== undefined && phoneNumber !== "") {
          AuthService.getUsrDtlByPhone(phoneNumber).then(resp => {  //console.log(resp);
            if (resp.hasOwnProperty('userIdentify')) {
              this.userId = resp.userIdentify.userId;
              var items = resp.wallets;
              var wallets = [];
              for (var key in items) {
                var WalletId = items[key].walletId;
                var WalletName = items[key].walletGroup.name;
                wallets.push({value: WalletId, text: WalletName});
              }
              var displayName = resp.firstName + " " + resp.lastName;
              this.displayName = displayName.trim();
              this.optWallet = wallets;
              //this.optWallet = this.walletGroup;
              if (this.seenuser) this.seenuser = false;
            } else {
                this.displayName = "";
                this.userId = "";
                this.seenuser = true;
                document.getElementById("phoneNumberSearch").style.border = "1px solid red";
                this.coloruser="danger"; this.msguser = "User Not Found";
                var dis = this;
                setTimeout( function(){ dis.seenuser=false; document.getElementById("phoneNumberSearch").style.removeProperty("border"); } , 5000);
            }

          }, error => {
            console.log(error);
            this.loading = false;
            document.getElementById("phoneNumberSearch").style.border = "1px solid red";
          });
        } else {
          document.getElementById("phoneNumberSearch").style.border = "1px solid red";
          this.coloruser="danger"; this.msguser = "User Not Found";
          this.seenuser=true;
          var dis = this;
          setTimeout( function(){ dis.seenuser=false; document.getElementById("phoneNumberSearch").style.removeProperty("border"); } , 5000);
        }
      },  

      getConfigList: function() {
          AuthService.getConfigList().then(resp => { 

            var currSrc=[]; var temp = [];
            var arrcurrency = resp.currency;
            for (const item of arrcurrency){ temp.push(item); }
            temp.sort(); 
            for (var key in temp) {
                var curr = temp[key];  
                currSrc.push({ value: curr , text: curr });
            }
            this.optCurrency = currSrc; 

            var typeSrc=[];
            var arrTypes = resp.systemTransactionTypes;
            for (const item2 of arrTypes) {                    
                if(item2 !== "REDEEM") { typeSrc.push({ value: item2 , text: item2 }); }
            }
          
            this.optTrType = typeSrc;
            
           }, error => {
            console.log('error');    
          });
      },   
      
    }
  }
</script>

<style>
/* .list-group shadow vbt-autcomplete-list {  background: black; } */
[role~=listbox]{ background: black; }
</style>



